@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');
@font-face {
  font-family: 'OpenSans';
  src: url('../public//fonts/OpenSans-Regular.ttf') format('truetype');
}
.App {
  flex-direction: 'column';
  width: 100%;
  height: 100%;
  font-family: 'OpenSans', sans-serif;
}
.header {
  width: 100vw;
  min-height: 40px;
  height: 70px;
  position: relative;
  background-color: green;
}
* {
  font-family: 'OpenSans', sans-serif !important;
}
