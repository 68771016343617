.second-type-info {
  display: flex;
  flex-direction: column;
  text-align: left;
  background-color: rgb(238, 241, 242, 0.52);
  /* width: 45%; */
  border-radius: 15px;
  padding: 10px;
  margin: 10px;
  /* border: 2px solid #8cc8db; */
}
.first-type-info {
  display: flex;
  flex-direction: column;
  text-align: left;
  background-color: #fff;
  width: 45%;
  border-radius: 15px;
  padding: 10px;
  border: 2px solid #f4c274;
  justify-content: center;
}

.data-container {
  border: 3px solid #fff;
  padding: 20px;
  display: flex;
  flex-direction: row;
  width: 80%;
  display: flex;
  justify-content: space-between;
}
.single-data-container {
  border: 3px solid #fff;
  padding: 20px;
  display: flex;
  flex-direction: row;
  width: 80%;
  display: flex;
  justify-content: center;
}
.item {
  display: flex;
  flex-direction: column;
  text-align: left;
  background-color: #fff;
  width: 40%;
  border-radius: 15px;
  padding: 10px;
  border: 2px solid #8cc8db;
  justify-content: center;
}

.info-title {
  color: #035282;
  font-size: 20px;
  font-weight: 600;
  text-transform:capitalize;
  padding-top: 15px;
}

.info-icon {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
