.dialog-style {
  margin-top: 10px;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
}

.dialog-content-text {
  text-align: left;
  font-size: 18px;
  padding: 10px;
  margin-left: 10px;
}

.confirm-btn {
  background-color: #035282;
  color: white;
}

.cancel-btn {
  background-color: red;
  color: #035282;
  border: 1px solid #035282;
}
