.rbc-calendar {
  font-family: open sans;
  background-color: white;
}
.rbc-row-content{
  background-color: #8CC8DB;
  display: none;
}
.rbc-time-view .rbc-row{
  min-height: 50px; 
  align-items: "center";
}

.rbc-toolbar {
  font-family: open sans;
}

.rbc-toolbar button {
  font-family: open sans;
}

.rbc-event-label {
  font-family: open sans;
}

.rbc-event-content {
  font-family: open sans;
  color: white;
}

/* Change font size and weight for dates */
.rbc-date-cell {
  font-size: 16px;
  font-weight: bold;
}

/* Add border around events */
.rbc-event {
  border: solid 2px #007bff;
}
span.rbc-toolbar-label {
  color: #035282;
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 700 !important;
}
.rbc-btn-group button {
  background-color: white;
  font-size: 16px !important;
}
span.rbc-label {
  color: #035282 !important;
}
.rbc-active {
  background-color: #f4c274 !important;
}
.month-name {
  color: #ccbe88;
  font-weight: bold;
  margin: 10px 0px;
}
.rbc-btn-group button .rbc-active {
  background-color: white !important;
}

div .rbc-header span {
  color: white !important;
  font-size: 17px !important;
  font-weight: 600 !important;
}
/* .rbc-toolbar .rbc-btn-group  */

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  color: white !important;
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  background-color: #e6e6e6;
  border-color: white;
}
.rbc-toolbar button {
  color: #035282;
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  border: 1px solid #eee;
  padding: 0.375rem 1rem;
  border-radius: 4px;
  line-height: normal;
  white-space: nowrap;
}
.rbc-toolbar .rbc-btn-group {
  color: #035282;
}

div .rbc-event-label {
  color: white !important;
  font-size: 15px;
  font-weight: 700;
}

.rbc-header {
  background-color: #8cc8db;
  min-height: 50px;
}
