.table-container {
  width: '100%';
  overflow: 'hidden';
  font-family: 'open sans';
}
.table-style {
  min-width: 400;
  border: '1px solid #8CC8DB';
}

.not-provided-data{
  color: "grey"; font-family: "open sans";
}
