.react-datepicker__day--selected {
  /* Customize the background color */
  background-color: #18c5bf;
  /* Customize other styles as needed */
}
.react-datepicker__day {
  color: #035282;
}
.react-datepicker__month-container .react-datepicker__header .react-datepicker__day-names {
  color: #035282;
}
.react-datepicker__header .react-datepicker__current-month .react-datepicker__day- {
  color: #035282;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #035282;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #035282;
}
.react-datepicker-wrapper {
  width: 100%;
}
