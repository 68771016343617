.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;

  justify-items: center;
  align-items: center;
  text-align: center;
  margin-top: 70px;
  border-radius: 10px;
  font-family: 'Open Sans';
}
.grid-container p {
  margin-bottom: 10px;
  font-size: 30px;
}
